<template>
    <div class="Imprint">
      <navBar></navBar>
      <textEdit title-user="Masterclass" title-intern-prop="masterclass" site-prop="masterclass"></textEdit>
      <textEdit title-user="Masterclass Extra" title-intern-prop="masterclass-extra" site-prop="masterclass"></textEdit>
    </div>
</template>

<script>
  import navBar from '@/components/NavBar.vue';
  import textEdit from '@/components/TextEdit.vue';
    export default {
      data: function () {
        return{

        }
      },
      methods: {

      },
      components: {
        navBar,
        textEdit
      },
    };
</script>

<style scoped>

</style>
